import React, { useCallback, useContext, useRef } from "react";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { template_image_light } from "../utils/config/image-links.js";
import { vacancies_data } from "../utils/config/vacancies.js";
import { page_links } from "../utils/config/page-links.js";
import { DivWithLoadingBackground } from "../components/ImageLoaders.jsx";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { UserContext } from "../context_providers/UserContext.jsx";

export default function Vacancies() {
  const buttonRefs = useRef([]);
  const { vacancyDepartments, selectedVacancyDepartment, setSelectedVacancyDepartment } = useContext(UserContext);

  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  return (
    <div className="flex flex-col px-[10%] py-12 bg-white gap-16">
      <div className="flex flex-col gap-4 p-8 w-full border border-gray-200 shadow-xl rounded-lg text-lg">
        <h1 className="text-2xl font-semibold text-castell">Disclaimer: Unsolicited CVs – Recruitment Agencies</h1>
        <p>
          Castell Group and its subsidiaries have an internal recruiting team. Agencies are hereby specifically directed
          not to contact Castell’s coworkers directly to present candidates – Castell’s Recruiting Team must present all
          candidates to hiring managers.
        </p>
        <p>
          To protect the interests of all parties, Castell Group will not accept unsolicited CVs from any source other
          than directly from a candidate. Castell Group will not pay any introduction fee’s for any placement resulting
          from the receipt of an unsolicited CV. Castell Group will consider any candidate for whom an Agency has
          submitted an unsolicited CV to have been referred by the Agency free of any charges or fees.
        </p>
        <p>
          Agencies must obtain advance written approval from Castell’s Recruiting Team to submit CVs, and then only in
          conjunction with Castell’s agreed payment terms and placement fee. Castell Group will not pay a fee to any
          Agency that does not have such agreement in place.
        </p>
      </div>

      <div className="flex flex-col border border-gray-200 shadow-xl rounded-lg p-4 sm:p-8 gap-6">
        <div className="flex w-full text-2xl font-semibold text-castell justify-center lg:justify-start border-b border-gray-200 pb-4">
          Vacancies
        </div>
        <div className="flex flex-col lg:flex-row gap-4 sm:gap-8">
          <div className="hidden lg:flex flex-col gap-2">
            {vacancyDepartments.map((department) => (
              <button
                aria-label={`Filter vacancies by ${department.name}`}
                key={department.id}
                className={`px-4 py-2 ${
                  department.id === selectedVacancyDepartment.id
                    ? "bg-castell text-white"
                    : "hover:bg-castellpastel-100"
                } font-semibold whitespace-nowrap text-left w-full border border-gray-200 rounded-sm ${handleScaleInlineTailwind300}`}
                onClick={() => setSelectedVacancyDepartment(department)}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              >
                {department.name}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full h-full gap-4 sm:gap-8 text-black font-semibold text-xs md:text-sm">
            {Object.values(vacancies_data)
              .filter((vacancy) => vacancy.active === true)
              .filter((vacancy) =>
                selectedVacancyDepartment.id === "all"
                  ? vacancy
                  : vacancy.department.id === selectedVacancyDepartment.id
              )
              .map((vacancy, index) => (
                <button
                  aria-label={`View ${vacancy.title}`}
                  key={vacancy.id}
                  ref={(el) => (buttonRefs.current[index] = el)}
                  onClick={() => (window.location.href = `${page_links["vacancy"].link}/${vacancy.id}`)}
                  className={`flex flex-col border border-gray-200 rounded-lg overflow-hidden transition-transform duration-150 shadow-lg`}
                  onMouseMove={(e) => handleMouseMoveCallback(e, index)}
                  onMouseLeave={() => handleMouseLeaveCallback(index)}
                >
                  <DivWithLoadingBackground
                    className="flex w-full h-52 2xl:h-64 bg-cover border-b border-gray-200 bg-center bg-blue-200"
                    imageUrl={insertTextbeforeUrlExtention(vacancy.image.image_url || template_image_light, "_tinyres")}
                  />
                  <div className="flex flex-col w-full items-center px-2 py-2">
                    <div className="whitespace-nowrap">{vacancy.title}</div>
                  </div>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
